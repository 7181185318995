
/* @media only screen and (max-width: 380px) {
  .inner-wrap {
    margin-top: 120%;
  }
} */

.inner-wrap {
  text-align: center;
  margin-top: 105%;
}

.title-font {
  color: white;
  font-size: 32px;
  font-weight: 600;
}

.underline-style {
  width: 50%;
  height: 3px;
  background: #C4113C;
  border: none;
}

.sub-title-font {
  color: white;
  font-size: 16px;
  font-weight: 400;
  width: 80%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.form-container {
  justify-content: center;
  display: flex;
}