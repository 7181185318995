@import url('https://fonts.cdnfonts.com/css/hero-new');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

#root {
  /* background: rgb(59 59 59); */
}

.App {
  max-width: 430px;
  min-height: 100vh;
  margin: auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

button::-moz-focus-inner {
  opacity: 0;
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}


.d-flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}


.t-white {
  color: white;
}

.t-underline {
  text-decoration: underline;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}



.options-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #26D175;
  border: none;
}


.products-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1C1B1F;
  border: none;
  opacity: 0.38;
}

.receipt-container {
  width: 85%;
  border-radius: 8px;
  border: 0.5px solid #DEDEDE;
  background: #FFF;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
}

.after-upload-receipt-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  overflow: hidden;
  max-width: 80%;
  height: 150px;
}


.title {
  margin-top: 5%;
  margin-left: 2%;
  width: 100%;
}


.title .title-img {
  width: 70%;
}

@media only screen and (max-width: 350px) {
  .title .title-img {
    width: 100%;
  }

}

/* @media screen and (min-width: 350px) {


.title .img {
  height: 155px;
  left: 12%;
  object-fit: cover;
  position: absolute;
  top: 8%;
  width: auto;
}


} */

.header-text-img {
  margin-top: 3rem;
  object-fit: contain;
  width: 12rem;
  margin-left: -5rem;
  height: 7rem;
}

.myButton {
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  height: 40px;
  align-items: center;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.rewards-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  overflow-y: auto;
  height: 55vh;
}

.primary-buttons {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin: 5% 10% 2% 10%;
}


.footer {
  display: flex;
  justify-content: center;
  /* bottom: 0;
  position:absolute; */
  /* margin-top: 100px; */
  /* margin-bottom: 100px; */
}


.submissions-info {
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  height: 100px;
}

.rewards-container {
  background-color: white;
  border-radius: 10px;
  width: 80%;
  /* position: relative; */
  /* margin: 3% auto; */
}


@media only screen and (max-width: 300px) {
  .rewards-container {
    width: 100%;
    /* margin: 2% auto; */
  }

  .submissions-info {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
    width: 80%;
    margin: auto;
  }
}

.approve-info-container {
  border-radius: 10px;
  border: 3px solid #30E700;
  margin: 5% 5% 3% 5%;
  text-align: left;
}

.upload-outer-container {
  margin-top: 58%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.upload-img-container {
  border-radius: 10px;
  border: 1px dashed #B5B4B4;
  background: rgba(235, 235, 235, 0.29);
  height: 20vh;
  margin: 0 10% 0 10%;
}


.login-text-color.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
  color: white !important;
  padding-top: 3%;
  padding-left: 6%;
}

.auth-text-color.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
  color: white !important;
  padding-top: 3%;
}

.payment-details-error-message.ant-form-item .ant-form-item-explain-error {
  color: red !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border: 1px solid #FFF;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: white !important;
  background-color: transparent;
}

.input-wrapper {
  display: flex;
  width: 100%;
}

.full-name-asterisk::after {
  color: red;
  font-weight: bold;
  content: "*";
  position: absolute;
  bottom: 35%;
  right: 71%;
}